<template>
  <v-container>
    <v-data-table :loading="loading_data_table" :headers="headers" :items="desserts" sort-by="corporate_name"
      class="elevation-1" :pageCount="numberOfPagesPaginate" :page="currentPagePaginate"
      :server-items-length="totalStagesPaginate" :options.sync="options" :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true
      }">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Ordem de Serviço</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-2" color="primary" fab small dark v-bind="attrs" v-on="on">
                <v-icon>mdi-tools</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="showConfigIntegration()">
                <v-icon class="mr-2"> mdi-cog</v-icon>
                Integração

              </v-list-item>

            </v-list>
          </v-menu>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="loadingRefresh" :loading="loadingRefresh" @click="updateOrderService()" fab small
                color="primary" v-bind="attrs" v-on="on">
                <v-icon>mdi-refresh </v-icon>
              </v-btn>
            </template>
            <span>Atualizar Ordem de Serviços</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-text-field rounded dense outlined @keyup.enter="searchItems()" v-model="search" append-icon="mdi-magnify" label="Localizar" @click:append="searchItems()"
            single-line hide-details class="mr-8"></v-text-field>
           <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:item.supplier="{ item }">
        {{ item.contractor.fantasy_name }}
      </template>

      <template v-slot:item.approved_by="{ item }">
        {{ convertJson(item.user_approved).name }}
      </template>

      <template v-slot:item.generated_by="{ item }">
        {{ convertJson(item.user_generated).name }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div style="white-space: nowrap;">

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on" class="mr-2" icon :loading="item.loading" @click="sendExportOrderService(item)">
                <v-icon> mdi-download-box </v-icon>
              </v-btn>
            </template>
            <span>Ordem de Serviço</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" :loading="item.loadingRemaning" v-on="on" class="mr-2" icon @click="sendExportRemaining(item)">
                <v-icon> mdi-download-box-outline </v-icon>
              </v-btn>
            </template>
            <span>Ordem de Serviço Remanescente</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="error" v-bind="attrs" v-on="on" class="mr-2" icon @click="deleteItem(item)">
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>Apagar</span>
          </v-tooltip>

        </div>
      </template>
      <template v-slot:no-data> Nenhum registro encontrado </template>
    </v-data-table>

    <!-- dialog visualizar -->
    <v-dialog v-model="dialog" max-width="900px">

      <v-card>
        <div class="title_body">
          <span class="text_title text-h6">EMPREITEIRO</span>
          <span class="btn_close">
            <v-btn icon dark @click="dialog = false">
              <v-icon dark> mdi-close </v-icon>
            </v-btn>
          </span>
        </div>

        <v-card-text>
          <v-row no-gutters>
            <v-col cols="2">
              <v-text-field disabled class="mr-2" outlined v-model="editedItem.id" label="Codigo"></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field disabled class="mr-2" outlined v-model="editedItem.cpf" label="CPF"></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field disabled outlined v-model="editedItem.cnpj" label="CNPJ"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field disabled class="mr-2" outlined v-model="editedItem.fantasy_name"
                label="Nome"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field disabled outlined v-model="editedItem.company_name" label="Razão Social"></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-text-field disabled class="mr-2" outlined v-model="editedItem.state_registration"
                label="Inscrição Estadual"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field disabled outlined v-model="editedItem.municipal_registration"
                label="Inscrição Municipal"></v-text-field>
            </v-col>

          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-text-field disabled class="mr-2" outlined v-model="editedItem.city" label="Cidade"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field class="mr-2" disabled outlined v-model="editedItem.state" label="Estado"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field disabled outlined v-model="editedItem.cep" label="CEP"></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-text-field disabled outlined v-model="editedItem.email" label="Email"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mb-4"></v-divider>
        <v-card-text>
          <v-row no-gutters v-for="(contact, index) of editedItem.contact" :key="index">
            <v-col>
              <v-text-field dense class="mr-2" disabled outlined v-model="contact.name" label="Nome"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field dense class="mr-2" disabled outlined v-model="contact.occupation"
                label="Função"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field dense class="mr-2" disabled outlined v-model="contact.telephone"
                label="Telefone"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field dense disabled outlined v-model="contact.email" label="Email"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mx-0 mt-3 mr-1" text @click="dialog = false">Fechar</v-btn>
          <!-- <v-btn class="primary mx-0 mt-3" :loading="btnLoadingSave" :disabled="btnLoadingSave" @click="save">Salvar</v-btn>  -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DE CONFIGURACAO -->
    <v-dialog v-model="dialogConfigIntegration" width="800">
      <v-card :disabled="loadingConfigIntegration">
        <BarTitle title="Configuração de Integração" @close="dialogConfigIntegration = false"></BarTitle>
        <v-card-text>
          <v-text-field v-model="configIntegration.url" outlined label="Dominio de integração"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mx-0 mt-3 mr-1" x-large text @click="dialogConfigIntegration = false">Fechar</v-btn>
          <v-btn class="primary mx-0 mt-3" x-large :loading="btnLoadingConfigSave" :disabled="btnLoadingConfigSave"
            @click="saveConfigIntegration()">Salvar</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>


    <!-- DIALOG DE EXCLUSAO -->
    <MsgDelete :loading="loadingDelete" v-model="dialogDelete" @close="dialogDelete = false" @confirm="confirmDeleteItem()"></MsgDelete>

  </v-container>
</template>

<script>
import {
  mapActions
} from 'vuex/dist/vuex.common.js';
import BarTitle from '@/pages/Components/BarTitle.vue';
import MsgDelete from '@/pages/Components/MsgDelete.vue';
export default {
  components: {
    BarTitle,
    MsgDelete
  },
  data: () => ({
    dialogConfigIntegration: false,
    configIntegration: {},
    btnLoadingConfigSave: false,
    loadingConfigIntegration: false,

    loadingRefresh: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    options: {},
    search: '',

    loadingDelete: false,
    btnLoadingSave: false,
    loading_data_table: false,
    dialog: false,
    dialogDelete: false,
    headers: [{
      text: "NUMERO",
      align: "start",
      sortable: false,
      value: "number"
    },
    {
      text: "FORNECEDOR",
      value: "supplier"
    },
    {
      text: "GERADO POR",
      value: "generated_by"
    },
    {
      text: "CRIADO_EM",
      value: "approved_by"
    },
    {
      text: "",
      value: "actions",
      align: "end",
      sortable: false
    },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {
      id: "",
      code: "",
      name: "",
    },
  }),

  computed: {

  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    options: {
      handler() {
        this.initialize();
      },
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions('OrderService', [
      'index',
      'getUpdateOrderService',
      'exportOrderService',
      'exportRemaining',
      'storeConfigIntegration',
      'loadConfigIntegration',
      'deleteItemService',
    ]),
    initialize() {
      this.loading_data_table = true;
      const {
        page,
        itemsPerPage
      } = this.options;
      const data = {
        itemsPerPage: itemsPerPage || 10,
        page: page,
        search: this.search,
      }
      this.index(data).then((response) => {
        this.desserts = response.data.data;
        this.totalStagesPaginate = response.data.total
        this.numberOfPagesPaginate = response.data.last_page
        this.currentPagePaginate = response.data.current_page
        this.loading_data_table = false;
      });
    },

    showConfigIntegration() {
      // carrega as configuracoes de integracao de empreiteiro
      this.loadingConfigIntegration = true
      this.loadConfigIntegration().then((response) => {
        this.configIntegration.url = response.data.url
      }).finally(() => {
        this.loadingConfigIntegration = false
      })
      this.dialogConfigIntegration = true
    },
    saveConfigIntegration() {

      this.storeConfigIntegration(this.configIntegration).then(() => {
        this.dialogConfigIntegration = false
        this.$toast.success('Salvo com sucesso');
      }).finally(() => {
        this.btnLoadingConfigSave = false
      })
    },

    sendExportOrderService(order_service) {

        this.$toast.success('Gerando PDF')
      this.exportOrderService(order_service.id)
        .then((response) => {

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'export.pdf');
          document.body.appendChild(fileLink);
          fileLink.click();

        }).finally(()=>{

        })
    },

    sendExportRemaining(order_service) {

      this.$toast.success('Gerando PDF')
      this.exportRemaining(order_service.id)
        .then((response) => {

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'export.pdf');
          document.body.appendChild(fileLink);
          fileLink.click();

        }).finally(()=>{

        })
    },

    searchItems() {
    //   this.options = {}
      this.initialize()
    },
    deleteItem(orderService) {
      this.editedItem = orderService
      this.dialogDelete = true
    },
    confirmDeleteItem() {
      this.loadingDelete = true
      this.deleteItemService(this.editedItem.id).then(() => {
        //retira o item da lista
        let index = this.desserts.indexOf(this.editedItem)
        this.desserts.splice(index, 1)

        this.$toast.success('Deletado com sucesso.')
        this.dialogDelete = false
      }).finally(() => {
        this.loadingDelete = false
      })
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    updateOrderService() {
      this.loadingRefresh = true
      this.getUpdateOrderService().then(() => {
        this.$toast.success('Atualizado com sucesso.')
        this.initialize()
      }).finally(() => {
        this.loadingRefresh = false
      })

    },
    dialogView(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;

    },
    convertJson(item) {

      return JSON.parse(item)
    }

  },
};
</script>
